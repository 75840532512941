<template>
  <div>
    <modal model="showModal" :title="title" :callback="onSubmit">
      <template slot="content">
        <v-row>
          <v-col cols="12" v-if="type === 'contact'">
            <label for="">Email</label>
            <v-text-field
              @blur="$v.customer.email.$touch()"
              :error-messages="
                !$v.customer.email.required && $v.customer.email.$invalid
                  ? 'Email required'
                  : !$v.customer.email.email && $v.customer.email.$invalid
                  ? 'Invalid email'
                  : ''
              "
              v-model="$v.customer.email.$model"
            ></v-text-field>
          </v-col>
          <v-col v-else cols="12">
            <v-row>
              <v-col cols="6">
                <label for="">First name</label>
                <v-text-field v-model="customer.firstName"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="">Last name</label>
                <v-text-field v-model="customer.lastName"></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">Address</label>
                <v-text-field v-model="customer.customerAttribute.address1"></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="">Apartment, suite, etc... (optional)</label>
                <v-text-field
                  v-model="customer.customerAttribute.address2"
                  placeholder="Apartment, suite, etc... (optional)"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="">City</label>
                <v-text-field v-model="customer.customerAttribute.city"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="">Phone number</label>
                <v-text-field v-model="customer.phone"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="">Province</label>
                <v-select
                  v-if="provinces"
                  v-model="customer.customerAttribute.province"
                  :items="provinces"
                  item-text="name"
                  item-value="name"
                ></v-select>
                <v-text-field v-else v-model="customer.customerAttribute.province"></v-text-field>
              </v-col>
              <v-col cols="6">
                <label for="">Country</label>
                <v-select
                  v-model="customer.customerAttribute.country"
                  @change="loadProvinces"
                  :items="countries"
                  item-text="name"
                  item-value="name"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <label for="">ZIP/Postal Code</label>
                <v-text-field v-model="customer.customerAttribute.zip"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { required, minLength, maxLength, minValue, maxValue, email, helpers, decimal } from 'vuelidate/lib/validators';
import { storeService } from '@/apis/store.s';
import { customerService } from '@/apis/customer';

export default {
  data() {
    return {
      showModal: false,
      title: 'Edit shipping address',
      type: 'contact',
      countries: [],
      provinces: [],
      isLoading: false,
      customer: {
        email: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        province: 'Alabama',
        province_code: 'AL',
        country_code: 'US',
        country: 'United States',
        zip: '',
        phone: '',
      },
    };
  },
  validations: {
    customer: {
      email: {
        required,
        email,
      },
    },
  },
  created() {
    this.getCountries();
    if (this.$parent.data.customerInfo[0] && this.$parent.data.customerInfo[0].customerAttribute) {
      this.customer = { ...this.$parent.data.customerInfo[0] };
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.$v.customer.$touch();
      if (!this.$v.$invalid) {
        try {
          this.customer.full_name = this.customer.firstName + ' ' + this.customer.lastName;
          let customerData = await customerService.updateInOrder(this.customer, this.$parent.data.id);
          this.isLoading = false;
          this.$emit('reloadOrder');
          this.showModal = false;
        } catch (error) {
          this.error = error;
          this.showModal = false;
          this.isLoading = false;
        }
      }
    },
    async getCountries() {
      try {
        let countriesData = await storeService.getCountries();
        this.countries = countriesData.data;
        let country = this.countries
          ? this.countries.find(c => c.name == this.customer.customerAttribute.country)
          : null;
        this.provinces = country ? country.provinces : null;
      } catch (error) {
        console.log(error);
      }
    },
    onShowModal() {
      this.showModal = !this.showModal;
    },
    loadProvinces() {
      let country = this.countries ? this.countries.find(c => c.name == this.customer.customerAttribute.country) : null;
      this.provinces = country ? country.provinces : null;
      this.customer.province = '';
    },
  },
  watch: {
    'customer.country': function() {
      let country = this.countries ? this.countries.find(c => c.name == this.customer.country) : null;
      this.customer.customerAttribute.country_code = country ? country.code : '';
    },
    'customer.province': function() {
      let province = this.provinces ? this.provinces.find(p => p.name == this.customer.province) : null;
      this.customer.customerAttribute.province_code = province ? province.code : '';
    },
  },
};
</script>
